// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../../../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-get-help-js": () => import("./../../../src/pages/get-help.js" /* webpackChunkName: "component---src-pages-get-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-support-us-js": () => import("./../../../src/pages/support-us.js" /* webpackChunkName: "component---src-pages-support-us-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-work-with-us-js": () => import("./../../../src/pages/work-with-us.js" /* webpackChunkName: "component---src-pages-work-with-us-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blogCategory.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-fundraising-js": () => import("./../../../src/templates/fundraising.js" /* webpackChunkName: "component---src-templates-fundraising-js" */),
  "component---src-templates-general-template-js": () => import("./../../../src/templates/general-template.js" /* webpackChunkName: "component---src-templates-general-template-js" */),
  "component---src-templates-job-listing-js": () => import("./../../../src/templates/job-listing.js" /* webpackChunkName: "component---src-templates-job-listing-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

