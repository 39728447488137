const React = require('react');

exports.onRenderBody = ({ setPostBodyComponents }) => {
  const attachCode = `
if (ga) {
  ga('require', 'linker');
  ga('linker:autoLink', ['wagec.org.au', 'womens-and-girls-emergency-centre-wagec.myshopify.com']);
}`;

  setPostBodyComponents([<script dangerouslySetInnerHTML={{
    __html: attachCode,
  }}
  />]);
};
